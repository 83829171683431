import styled, { css } from "styled-components";

import { lighten } from "utils/color";

const Th = styled.th<{ minWidth?: string; width?: string }>`
  ${(props) => css`
    padding: ${props.theme.spacing.m}em;
    position: sticky;
    top: 0;
    color: ${props.theme.colors.main};
    font-size: ${props.theme.text.m}em;
    font-family: Roboto;
    font-weight: 500;
    background: ${lighten(props.theme.palettes.grey, 0.45)};
    ${props.width && `width:${props.width}`};
    ${props.minWidth && `min-width:${props.minWidth}`};
    white-space: nowrap;
  `}
`;

export default Th;
