import styled from "styled-components";
import {
  space,
  SpaceProps,
  flexbox,
  layout,
  FlexboxProps,
  LayoutProps,
  typography,
  TypographyProps,
  color,
  ColorProps,
} from "styled-system";

const Box = styled("div")<
  SpaceProps & FlexboxProps & LayoutProps & TypographyProps & ColorProps
>(space, flexbox, layout, typography, color);

export default Box;
