import { ReactNode } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css, useTheme } from "styled-components";

import Box from "./Box";
import Button from "./Button";

interface Props {
  isVisible: boolean;
  children: ReactNode;
  hide: () => void;
  title: string;
  onClickConfirm: () => void;
  confirmTitle: string;
  isLoading: boolean;
}

const Container = styled.div<{ isVisible: boolean }>`
  ${(props) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.8);
    display: ${props.isVisible ? "flex" : "none"};
    align-items: center;
    justify-content: center;
  `}
`;

const Content = styled.div`
  ${(props) => css`
    position: relative;
    background: ${props.theme.colors.white};
    border-radius: ${props.theme.borderRadii.s}px;
  `}
`;

const Header = styled.div`
  ${(props) => css`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${props.theme.colors.border};
    padding: ${props.theme.spacing.l}px ${props.theme.spacing.l}px;
  `}
`;

const CloseBtn = styled.div`
  ${(props) => css`
    cursor: pointer;
    padding: ${props.theme.spacing.s}px;
  `}
`;

const Footer = styled.div`
  ${(props) => css`
    display: flex;
    justify-content: flex-end;
    padding: ${props.theme.spacing.l}px ${props.theme.spacing.l}px;
  `}
`;

const ChoiceModal = (props: Props) => {
  const theme = useTheme();

  return (
    <Container isVisible={props.isVisible}>
      <Content>
        <Header>
          <Box
            fontSize={`${theme.text.l}px`}
            fontWeight={500}
            color="main"
            flex={1}
          >
            {props.title}
          </Box>
          <CloseBtn onClick={props.hide}>
            <FontAwesomeIcon
              icon={faTimes}
              size="1x"
              color={theme.colors.main}
            />
          </CloseBtn>
        </Header>
        <Box pt={`${theme.spacing.l}px`} px={`${theme.spacing.l}px`}>
          {props.children}
        </Box>
        <Footer>
          <Box mr={`${theme.spacing.m}px`}>
            <Button
              title="БУЦАХ"
              onClick={props.hide}
            />
          </Box>
          <Button
            title={props.confirmTitle}
            onClick={props.onClickConfirm}
            isLoading={props.isLoading}
          />
        </Footer>
      </Content>
    </Container>
  );
};

export default ChoiceModal;
