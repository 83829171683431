import { useEffect, useState } from "react";
import axios from "axios";
import styled, { css, useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  faTrash,
  faEdit,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInternetExplorer,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import {
  Box,
  Button,
  Header,
  Modal,
  ModalContent,
  ModalFooter,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "common/components";
import { IBranch } from "types";
import IconButton from "common/components/IconButton";

const Img = styled.div<{ src?: string }>`
  ${(props) => css`
    width: 100px;
    height: 70px;
    background-image: url(${props.src
      ? `${process.env.REACT_APP_IMG_URL}${props.src}`
      : "/images/no-image.png"});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  `}
`;

const SocialContainer = styled.div`
  ${(props) => css`
    display: flex;
    & > :nth-child(n + 2) {
      margin-left: ${props.theme.spacing.xs}em;
    }
  `}
`;

const Branch = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<IBranch | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [branches, setBranches] = useState<IBranch[]>([]);

  const hideModal = () => {
    setDeleteModal(false);
  };

  useEffect(() => {
    axios.get("/branch").then(({ data }) => {
      setBranches(data);
    });
  }, []);

  const navigateToAddCoin = () => {
    navigate(`add`);
  };

  const onDelete = () => {
    setActionLoading(true);
    axios
      .post(`/branch/${selectedBranch?.id}/delete`)
      .then(() => {
        const clone = [...branches];
        const newBranches = clone.filter((i) => i.id !== selectedBranch?.id);
        setBranches(newBranches);
        setActionLoading(false);
        hideModal();
      })
      .catch((e) => {
        setActionLoading(false);
        hideModal();
      });
  };

  return (
    <Box>
      <Header
        title={`Салбар (${branches.length})`}
        rightBtn={{ title: "Салбар нэмэх", onClick: navigateToAddCoin }}
      />
      <Table>
        <Thead>
          <Tr>
            <Th minWidth="50px">
              <Box textAlign="right">#</Box>
            </Th>
            <Th>
              <Box textAlign="left">Нэр</Box>
            </Th>
            <Th>
              <Box textAlign="left">Хот/Аймаг</Box>
            </Th>
            <Th>
              <Box textAlign="left">Дүүрэг/Сум</Box>
            </Th>
            <Th width="100%">
              <Box textAlign="left">Хаяг</Box>
            </Th>
            <Th>
              <Box textAlign="left">Цагийн хуваарь</Box>
            </Th>
            <Th>
              <Box textAlign="left">ХХ</Box>
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {branches.map((item, index) => {
            const onClickEdit = () => {
              navigate(`edit/${item.id}`, { state: item });
            };

            const onClickDelete = () => {
              setDeleteModal(true);
              setSelectedBranch(item);
            };

            return (
              <Tr key={item?.id}>
                <Td>
                  <Box fontFamily="Roboto" textAlign="right">
                    {index + 1}
                  </Box>
                </Td>
                <Td>
                  <Box fontFamily="Roboto" display="flex" alignItems="center">
                    <Box marginRight={`${theme.spacing.xs}em`}>
                      <Img src={item?.image?.path} />
                    </Box>
                    <Box>{item.name}</Box>
                  </Box>
                </Td>
                <Td>
                  {item?.province && (
                    <Box fontFamily="Roboto">{item.province}</Box>
                  )}
                </Td>
                <Td>
                  {item?.district && (
                    <Box fontFamily="Roboto">{item.district}</Box>
                  )}
                </Td>
                <Td>
                  {item?.address && (
                    <Box fontFamily="Roboto" mt={`${theme.spacing.xs}em`}>
                      {item.address}
                    </Box>
                  )}
                  {item?.phone && (
                    <Box fontFamily="Roboto" mt={`${theme.spacing.xs}em`}>
                      {item.phone}
                    </Box>
                  )}
                  {(item?.web || item?.facebook || item?.youtube) && (
                    <Box mt={`${theme.spacing.xs}em`}>
                      <SocialContainer>
                        {item.web && (
                          <a href={item.web} target="_blank">
                            <FontAwesomeIcon
                              icon={faInternetExplorer}
                              size="lg"
                              color={theme.colors.main}
                            />
                          </a>
                        )}
                        {item.facebook && (
                          <a href={item.facebook} target="_blank">
                            <FontAwesomeIcon
                              icon={faFacebook}
                              size="lg"
                              color={theme.colors.main}
                            />
                          </a>
                        )}
                        {item.youtube && (
                          <a
                            href={`https://www.youtube.com/watch?v=${item.youtube}`}
                            target="_blank"
                          >
                            <FontAwesomeIcon
                              icon={faYoutube}
                              size="lg"
                              color={theme.colors.main}
                            />
                          </a>
                        )}
                      </SocialContainer>
                    </Box>
                  )}
                </Td>
                <Td>
                  <Box fontFamily="Roboto">
                    {item?.timeTables &&
                      item.timeTables.map((item, index) => (
                        <Box key={index}>{item}</Box>
                      ))}
                  </Box>
                </Td>
                <Td>
                  <FontAwesomeIcon
                    icon={item.isProject ? faCheck : faTimes}
                    color={
                      item.isProject ? theme.palettes.green : theme.palettes.red
                    }
                  />
                </Td>
                <Td>
                  <Box display="flex">
                    <IconButton
                      icon={faEdit}
                      color={theme.palettes.orange}
                      onClick={onClickEdit}
                    />
                    <Box ml={`${theme.spacing.xs}em`}>
                      <IconButton
                        icon={faTrash}
                        color={theme.palettes.red}
                        onClick={onClickDelete}
                      />
                    </Box>
                  </Box>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Modal isVisible={deleteModal} hide={hideModal} title="Устгах">
        <ModalContent>
          <Box display="flex" fontFamily="Roboto">
            Та{" "}
            <Box color="error" px={`${theme.spacing.xs}em`}>
              {selectedBranch?.name}
            </Box>{" "}
            нэртэй салбарыг устгахдаа итгэлтэй байна уу?
          </Box>
        </ModalContent>
        <ModalFooter>
          <Button title="Буцах" onClick={hideModal} />
          <Box ml={`${theme.spacing.m}em`}>
            <Button
              title="Устгах"
              onClick={onDelete}
              bgColor={theme.palettes.red}
              textColor={theme.palettes.white}
              isLoading={actionLoading}
            />
          </Box>
        </ModalFooter>
      </Modal>
    </Box>
  );
};

export default Branch;
