import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styled, { css, useTheme } from "styled-components";

import Box from "./Box";

interface Props {
  icon?: IconProp;
  title: string;
  onClick: () => void;
  isLoading?: boolean;
  bgColor?: string;
  textColor?: string;
  fluid?: boolean;
  size?: number;
}

const Container = styled.div<{
  bgColor?: string;
  textColor?: string;
  fluid?: boolean;
}>`
  ${(props) => css`
    display: ${props.fluid ? "block" : "inline-block"};
    background: ${props.bgColor || props.theme.colors.button};
    border-radius: ${props.theme.borderRadii.m}em;
    color: ${props.textColor || "#757575"};
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding: 0.75em 1em;
  `}
`;

const Button = (props: Props) => {
  const theme = useTheme();

  const onClick = () => {
    if (!props.isLoading) {
      props.onClick();
    }
  };

  return (
    <Container
      onClick={onClick}
      fluid={props.fluid}
      bgColor={props.bgColor}
      textColor={props.textColor}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        {props.isLoading ? (
          <FontAwesomeIcon icon={faSpinner} size="1x" spin />
        ) : (
          <>
            {props.icon && (
              <Box marginRight={`${theme.spacing.s}em`}>
                <FontAwesomeIcon icon={props.icon} size="xs" />
              </Box>
            )}
            <Box
              fontWeight={500}
              fontSize={`${props.size || theme.text.m}rem`}
            >
              {props.title}
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Button;
