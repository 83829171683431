import { useState } from "react";
import axios from "axios";

import { IImage } from "types";

const ImageHook = (props?: IImage) => {
  const [image, setImage] = useState<IImage | undefined>(props);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const upload = async (img: any) => {
    setIsLoading(true);

    await axios
      .post("/image/create", img)
      .then(({ data }) => {
        setImage({ id: data.id, filename: data.filename, path: data.path });
        setIsLoading(false);
      })
      .catch((e) => setErrorMsg(e.message));
  };

  const reset = () => {
    setIsLoading(false);
    setImage(undefined);
  };

  return { image, upload, isLoading, reset, errorMsg };
};

export default ImageHook;
