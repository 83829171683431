import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "styled-components";

import Box from "./Box";
import Button from "./Button";

interface Props {
  title: string;
  rightBtn?: {
    title: string;
    onClick: () => void;
  };
}

const Header = (props: Props) => {
  const theme = useTheme();

  return (
    <Box px={`${theme.spacing.l}em`}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box fontSize={`${theme.title.m}em`} color="main" fontFamily="Roboto">
          {props.title}
        </Box>
        {props.rightBtn && (
          <Button
            title={props.rightBtn.title}
            icon={faPlus}
            onClick={props.rightBtn.onClick}
            bgColor={theme.colors.main}
            textColor={theme.palettes.white}
          />
        )}
      </Box>
      <Box height="1px" backgroundColor="border" my={`${theme.spacing.m}em`} />
    </Box>
  );
};

export default Header;
