import { InputHTMLAttributes } from "react";
import styled, { css, useTheme } from "styled-components";

import Box from "./Box";
import { lighten } from "utils/color";

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  required?: boolean;
  error?: boolean;
}

const InputContainer = styled.div<{ error?: boolean }>`
  ${(props) => css`
    background: ${props.error
      ? lighten(props.theme.colors.error, 0.5)
      : props.theme.palettes.white};
    border-radius: ${props.theme.borderRadii.m}em;
    padding: ${props.theme.spacing.s}em;
    border: 1px solid
      ${props.error
        ? lighten(props.theme.colors.error, 0.35)
        : props.theme.colors.border};
    font-family: Roboto;
  `}
`;

const TextField = styled.textarea<{ error?: boolean }>`
  ${(props) => css`
    resize: none;
    outline: none;
    border: none;
    background: none;
    height: 100%;
    width: 100%;
    font-family: Roboto;
    color: ${props.error
      ? props.theme.colors.error
      : props.theme.palettes.black};
    &::placeholder {
      color: ${props.error
        ? lighten(props.theme.colors.error, 0.3)
        : lighten(props.theme.palettes.grey, 0.3)};
    }
  `}
`;

const CustomTextField = (props: Props) => {
  const theme = useTheme();

  return (
    <Box>
      {props.label && (
        <Box
          mb={`${theme.spacing.xs}em`}
          display="flex"
          fontWeight={500}
          color={props.error ? theme.colors.error : theme.palettes.black}
        >
          <Box color="textDark">{props.label}</Box>
          <Box color={theme.palettes.red}>{props.required && "*"}</Box>
        </Box>
      )}
      <InputContainer error={props.error}>
        <TextField {...props} rows={5} />
      </InputContainer>
    </Box>
  );
};

export default CustomTextField;
