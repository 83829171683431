import { useEffect, useState } from "react";
import axios from "axios";
import styled, { css, useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

import {
  Box,
  Button,
  Header,
  Modal,
  ModalContent,
  ModalFooter,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "common/components";
import { INews } from "types";
import IconButton from "common/components/IconButton";

const Img = styled.div<{ src?: string }>`
  ${(props) => css`
    width: 100px;
    height: 70px;
    background-image: url(${props.src
      ? `${process.env.REACT_APP_IMG_URL}${props.src}`
      : "/images/no-image.png"});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  `}
`;

const News = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedNews, setSelectedNews] = useState<INews | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [news, setNews] = useState<INews[]>([]);

  const hideModal = () => {
    setDeleteModal(false);
  };

  useEffect(() => {
    axios.get("/news").then(({ data }) => {
      setNews(data);
    });
  }, []);

  const navigateToNews = () => {
    navigate(`add`);
  };

  const onDelete = () => {
    setActionLoading(true);
    axios
      .post(`/news/${selectedNews?.id}/delete`)
      .then(() => {
        const clone = [...news];
        const newNews = clone.filter((i) => i.id !== selectedNews?.id);
        setNews(newNews);
        setActionLoading(false);
        hideModal();
      })
      .catch((e) => {
        setActionLoading(false);
        hideModal();
      });
  };

  return (
    <Box>
      <Header
        title={`Мэдээ (${news.length})`}
        rightBtn={{ title: "Мэдээ нэмэх", onClick: navigateToNews }}
      />
      <Table>
        <Thead>
          <Tr>
            <Th minWidth="50px">
              <Box textAlign="right">#</Box>
            </Th>
            <Th width="100%">
              <Box textAlign="left">Нэр</Box>
            </Th>
            <Th>
              <Box textAlign="left">Огноо</Box>
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {news.map((item, index) => {
            const onClickEdit = () => {
              navigate(`edit/${item.id}`, { state: item });
            };

            const onClickDelete = () => {
              setDeleteModal(true);
              setSelectedNews(item);
            };

            return (
              <Tr key={item.id}>
                <Td>
                  <Box fontFamily="Roboto" textAlign="right">
                    {index + 1}
                  </Box>
                </Td>
                <Td>
                  <Box fontFamily="Roboto" display="flex" alignItems="center">
                    <Box marginRight={`${theme.spacing.xs}em`}>
                      <Img src={item.image?.path} />
                    </Box>
                    <Box>{item.title}</Box>
                  </Box>
                </Td>
                <Td>
                  <Box fontFamily="Roboto">{item.createdAt}</Box>
                </Td>
                <Td>
                  <Box display="flex">
                    <IconButton
                      icon={faEdit}
                      color={theme.palettes.orange}
                      onClick={onClickEdit}
                    />
                    <Box ml={`${theme.spacing.xs}em`}>
                      <IconButton
                        icon={faTrash}
                        color={theme.palettes.red}
                        onClick={onClickDelete}
                      />
                    </Box>
                  </Box>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Modal isVisible={deleteModal} hide={hideModal} title="Устгах">
        <ModalContent>
          <Box display="flex" fontFamily="Roboto">
            Та{" "}
            <Box color="error" px={`${theme.spacing.xs}em`}>
              {selectedNews?.title}
            </Box>{" "}
            гарчигтай мэдээг устгахдаа итгэлтэй байна уу?
          </Box>
        </ModalContent>
        <ModalFooter>
          <Button title="Буцах" onClick={hideModal} />
          <Box ml={`${theme.spacing.m}em`}>
            <Button
              title="Устгах"
              onClick={onDelete}
              bgColor={theme.palettes.red}
              textColor={theme.palettes.white}
              isLoading={actionLoading}
            />
          </Box>
        </ModalFooter>
      </Modal>
    </Box>
  );
};

export default News;
