import { useState } from "react";
import { useTheme } from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import dayjs from "dayjs";

import useImage from "hooks/imageHook";
import {
  Box,
  Button,
  Header,
  Input,
  InputImage,
  Modal,
  ModalContent,
} from "common/components";
import { IImage } from "types";
import Gallery from "./Gallery";
import useGallery from "./galleryHook";

const { CKEditor } = require("@ckeditor/ckeditor5-react");

const Schema = yup.object().shape({
  title: yup.string().required("Энэ талбар хоосон байна!"),
});

const AddOrEditNews = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state }: { state: any } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState(state?.content);
  const image = useImage(state?.image);
  const [galleries, setGalleries] = useState<IImage[]>(state?.galleries || []);
  const [errorMsg, setErrorMsg] = useState("");

  const changeGallery = (img: IImage) => {
    setGalleries([...galleries, img]);
  };

  const gallery = useGallery({ cb: changeGallery });

  const removeGallery = (id: number) => {
    const clone = [...galleries];
    const newGalleries = clone.filter((i) => i.id !== id);
    setGalleries(newGalleries);
  };

  const hideModal = () => {
    setErrorMsg("");
  };

  const handleEditorChange = (_: any, editor: any) => {
    setContent(editor.getData());
  };

  const { handleChange, handleBlur, handleSubmit, values, touched, errors } =
    useFormik({
      validationSchema: Schema,
      initialValues: {
        title: state?.title || undefined,
        createdAt: state?.createdAt || dayjs().format("YYYY-MM-DD"),
      },
      onSubmit: () => {
        setIsLoading(true);
        if (!state) {
          axios
            .post("/news/create", {
              title: values.title,
              content,
              imgId: image.image?.id,
              galleries: galleries.map((item) => item.id),
              createdAt: values.createdAt,
            })
            .then(() => {
              setIsLoading(false);
              navigate(-1);
            })
            .catch((e) => {
              setErrorMsg(e.type);
              setIsLoading(false);
            });
        } else {
          axios
            .post(`/news/${state.id}/update`, {
              title: values.title,
              content,
              imgId: image.image?.id,
              galleries: galleries.map((item) => item.id),
              createdAt: values.createdAt,
            })
            .then(() => {
              setIsLoading(false);
              navigate(-1);
            })
            .catch((e) => {
              setErrorMsg("Буруу огноо оруулсан байна!");
              setIsLoading(false);
            });
        }
      },
    });

  return (
    <>
      <Header title={state ? state.title : "Мэдээ нэмэх"} />
      <Box mx={`${theme.spacing.l}em`}>
        <InputImage
          upload={image.upload}
          img={image.image?.path}
          errorMsg={image.errorMsg}
        />
        <Box mt={`${theme.spacing.m}em`}>
          <Input
            placeholder="Гарчиг"
            label="Гарчиг"
            required
            defaultValue={values.title}
            error={touched.title && errors.title !== undefined}
            onChange={handleChange("title")}
            onBlur={handleBlur("title")}
          />
        </Box>
        <Box mt={`${theme.spacing.m}em`}>
          <Input
            placeholder="YYYY.MM.DD"
            label="Огноо"
            defaultValue={values.createdAt}
            onChange={handleChange("createdAt")}
            onBlur={handleBlur("createdAt")}
            type="date"
          />
        </Box>
        <Box mt={`${theme.spacing.m}em`} fontFamily="Roboto">
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "|",
                "link",
                "|",
                "bulletedList",
                "numberedList",
              ],
            }}
            data={content}
            onChange={handleEditorChange}
          />
        </Box>
        <Box mx={`-${theme.spacing.m}em`}>
          <Box flex={1} display="flex" flexWrap="wrap">
            {galleries.map((item, index) => {
              return (
                <Gallery key={`${index}`} img={item} remove={removeGallery} />
              );
            })}
            <Box mt={`${theme.spacing.m}em`} mx={`${theme.spacing.m}em`}>
              <InputImage upload={gallery.upload} errorMsg={image.errorMsg} />
            </Box>
          </Box>
        </Box>
        <Box mt={`${theme.spacing.m}em`}>
          <Button
            title={state ? "Засах" : "Нэмэх"}
            onClick={handleSubmit}
            isLoading={isLoading}
            bgColor={theme.colors.main}
            textColor={theme.palettes.white}
          />
        </Box>
      </Box>
      <Modal isVisible={!!errorMsg} hide={hideModal} title="Алдаа">
        <ModalContent>
          <Box display="flex" fontFamily="Roboto" color={theme.colors.error}>
            {errorMsg}
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddOrEditNews;
