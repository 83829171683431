import { ReactNode } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";
import {
  faCodeBranch,
  faImages,
  faNewspaper,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

import { Box } from "common/components";
import SingleMenu from "./SingleMenu";
import { destroyToken } from "utils/auth";

interface Props {
  children: ReactNode;
}

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Sidebar = styled.div`
  ${(props) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: ${props.theme.spacing.l}em 0;
    background: ${props.theme.palettes.black};
  `}
`;

const Img = styled.img`
  width: 100px;
  align-self: center;
`;

const Content = styled.div`
  overflow: scroll;
  flex: 1;
`;

const AdminLayout = (props: Props) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/");

  const logout = () => {
    destroyToken();
    navigate("/login");
  };

  return (
    <Container>
      <Sidebar>
        <Img src="images/logo.svg" />
        <Box flex={1} my={`${theme.spacing.l}em`}>
          <Link to="/branch">
            <SingleMenu
              title="Салбар"
              icon={faCodeBranch}
              isActive={path[1] === "branch"}
            />
          </Link>
          <Link to="/news">
            <SingleMenu
              title="Мэдээ"
              icon={faNewspaper}
              isActive={path[1] === "news"}
            />
          </Link>
          <Link to="/banner">
            <SingleMenu
              title="Сурталчилгаа"
              icon={faImages}
              isActive={path[1] === "banner"}
            />
          </Link>
        </Box>
        <Box onClick={logout}>
          <SingleMenu title="Гарах" icon={faSignOutAlt} isActive={false} />
        </Box>
      </Sidebar>
      <Content>
        <Box py={`${theme.spacing.l}em`}>{props.children}</Box>
      </Content>
    </Container>
  );
};

export default AdminLayout;
