export const destroyToken = () => {
  sessionStorage.removeItem("authToken");
};

export const getToken = () => {
  return sessionStorage.getItem("authToken");
};

export const setToken = (token: string) => {
  sessionStorage.setItem("authToken", token);
};
