import styled, { css, useTheme } from "styled-components";
import Select, { OnChangeValue } from "react-select";

import Box from "./Box";
import { lighten } from "utils/color";

interface Props {
  label?: string;
  required?: boolean;
  error?: boolean;
  options: { value: any; label: string }[];
  isMulti?: boolean;
  onChange: (option: any) => void;
  value: any;
  placeholder: string;
}

const CustomSelect = (props: Props) => {
  const theme = useTheme();

  const customStyles = {
    singleValue: (provided: any) => ({
      ...provided,
      color: props.error ? theme.colors.error : theme.palettes.black,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: props.error
        ? lighten(theme.colors.error, 0.3)
        : lighten(theme.palettes.grey, 0.3),
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
    }),
    input: (provided: any) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: 0,
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      padding: 0,
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      padding: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    control: () => ({
      display: "flex",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: props.error
        ? lighten(theme.colors.error, 0.35)
        : theme.colors.border,
      padding: `${theme.spacing.s}em`,
      borderRadius: `${theme.borderRadii.m}em`,
      background: props.error
        ? lighten(theme.colors.error, 0.5)
        : theme.palettes.white,
      fontFamily: "Roboto",
    }),
    option: (provided: any) => ({
      ...provided,
      fontFamily: "Roboto",
    }),
  };

  return (
    <Box flex={1}>
      {props.label && (
        <Box
          mb={`${theme.spacing.xs}em`}
          display="flex"
          fontWeight={500}
          color={props.error ? theme.colors.error : theme.palettes.black}
        >
          <Box>{props.label}</Box>
          <Box color={theme.palettes.red}>{props.required && "*"}</Box>
        </Box>
      )}
      <Select
        styles={customStyles}
        className="basic-single"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={props.placeholder}
        options={props.options}
        isMulti={props.isMulti}
        onChange={props.onChange}
        value={props.value}
      />
    </Box>
  );
};

export default CustomSelect;
