import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { lighten } from "utils/color";

interface Props {
  color: string;
  icon: IconProp;
  onClick: () => void;
}

const Container = styled.div<{ color: string }>`
  ${(props) => css`
    padding: ${props.theme.spacing.xs}em ${props.theme.spacing.s}em;
    background: ${lighten(props.color, 0.4)};
    border-radius: ${props.theme.borderRadii.m}em;
    cursor: pointer;
  `}
`;

const IconButton = (props: Props) => {
  return (
    <Container color={props.color} onClick={props.onClick}>
      <FontAwesomeIcon icon={props.icon} size="sm" color={props.color} />
    </Container>
  );
};

export default IconButton;
