import { TinyColor } from "@ctrl/tinycolor";

export const rgba = (hex: string, opacity: number) => {
  return new TinyColor(hex).setAlpha(opacity).toString();
};

export const lighten = (hex: string, amount: number) => {
  return new TinyColor(hex).lighten(amount * 100).toString();
};

export const darken = (hex: string, amount: number) => {
  return new TinyColor(hex).darken(amount * 100).toString();
};
