import { Dispatch, SetStateAction } from "react";
import styled, { css } from "styled-components";

interface Props {
  label: string;
  value: boolean;
  onChange: Dispatch<SetStateAction<boolean>>;
  editable?: boolean;
}

const Container = styled.label`
  ${(props) => css`
    font-family: Roboto;
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    line-height: 25px;
    padding-left: ${30 + props.theme.spacing.m}px;
    > input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    > span {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background: ${props.theme.colors.main3};
      border-radius: 3px;
      border: 2px solid ${props.theme.colors.main};
    }
    span:after {
      content: "";
      position: absolute;
      display: none;
    }
    > input:checked ~ span:after {
      display: block;
    }
    > span:after {
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid ${props.theme.colors.main};
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  `}
`;

const Checkbox = (props: Props) => {
  const onChange = () => {
    props.onChange(!props.value);
  };

  return (
    <Container>
      {props.label}
      <input type="checkbox" checked={props.value} onChange={onChange} />
      <span />
    </Container>
  );
};

export default Checkbox;
