import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styled, { css, useTheme } from "styled-components";

import { Box } from "common/components";

interface Props {
  title: string;
  icon: IconProp;
  isActive: boolean;
}

const Container = styled.div`
  ${(props) => css`
    cursor: pointer;
    position: relative;
    margin: ${props.theme.spacing.m}em 0;
  `}
`;

const Indicator = styled.div<{ isActive: boolean }>`
  ${(props) => css`
    width: 3px;
    position: absolute;
    top: 0;
    bottom: 0;
    background: ${props.theme.colors.main2};
    display: ${props.isActive ? "block" : "none"};
  `}
`;

const SingleMenu = (props: Props) => {
  const theme = useTheme();

  return (
    <Container>
      <Indicator isActive={props.isActive} />
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        px={`${theme.spacing.l}em`}
        py={`${theme.spacing.s}em`}
      >
        <FontAwesomeIcon
          icon={props.icon}
          size="1x"
          color={props.isActive ? theme.colors.main2 : theme.palettes.grey}
        />
        <Box
          color={props.isActive ? theme.colors.main2 : theme.palettes.grey}
          fontFamily="Roboto"
          ml={`${theme.spacing.m}em`}
        >
          {props.title}
        </Box>
      </Box>
    </Container>
  );
};

export default SingleMenu;
