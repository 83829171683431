import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css, useTheme } from "styled-components";

import Box from "./Box";

interface Props {
  upload: (img: any) => void;
  img?: string;
  isLoading?: boolean;
  errorMsg?: string;
}

const Container = styled.label`
  ${(props) => css`
    display: inline-block;
    cursor: pointer;
    > input {
      display: none;
    }
    > span {
      display: flex;
      height: 100px;
      width: 100px;
      border: 1px solid ${props.theme.colors.border};
      border-radius: ${props.theme.borderRadii.m}em;
      align-items: center;
      background: ${props.theme.palettes.white};
      justify-content: center;
    }
  `}
`;

const Image = styled.div<{ src: string }>`
  ${(props) => css`
    width: 100%;
    height: 100%;
    background-image: url(${process.env.REACT_APP_IMG_URL}${props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  `}
`;

const InputImage = (props: Props) => {
  const theme = useTheme();

  const onChange = (event: any) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      const data: any = new FormData();
      data.append("file", file);
      props.upload(data);
    }
  };

  console.log(props.img);

  return (
    <Container>
      <input
        type="file"
        onChange={onChange}
        accept="image/jpg, image/jpeg, image/png"
      />
      <span>
        {props.isLoading ? (
          <FontAwesomeIcon icon={faSpinner} size="2x" spin />
        ) : props.img ? (
          <Image src={props.img} />
        ) : (
          <FontAwesomeIcon
            icon={faPlus}
            size="2x"
            color={theme.palettes.black}
          />
        )}
      </span>
      <Box color="red" fontFamily="Roboto" mt={`${theme.spacing.xs}em`}>
        {props.errorMsg}
      </Box>
    </Container>
  );
};

export default InputImage;
