import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Login from "pages/login";
import Branch from "pages/branch";
import AddOrEditBranch from "pages/addOrEditBranch";
import News from "pages/News";
import AddOrEditNews from "pages/addOrEditNews";
import Banner from "pages/banner";

import { getToken } from "utils/auth";
import { theme } from "common/styles/theme";
import AdminLayout from "common/layouts/admin";

function RequireAuth({ children }: { children: JSX.Element }) {
  const token = getToken();

  let location = useLocation();

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <AdminLayout>{children}</AdminLayout>;
}

function RequireUnAuth({ children }: { children: JSX.Element }) {
  const token = getToken();

  if (token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/branch" />;
  }

  return children;
}

const R = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="*" element={<Navigate to="/branch" />} />
        <Route
          path="/login"
          element={
            <RequireUnAuth>
              <Login />
            </RequireUnAuth>
          }
        />
        <Route
          path="/branch"
          element={
            <RequireAuth>
              <Branch />
            </RequireAuth>
          }
        />
        <Route
          path="/branch/add"
          element={
            <RequireAuth>
              <AddOrEditBranch />
            </RequireAuth>
          }
        />
        <Route
          path="/branch/edit/:id"
          element={
            <RequireAuth>
              <AddOrEditBranch />
            </RequireAuth>
          }
        />
        <Route
          path="/news"
          element={
            <RequireAuth>
              <News />
            </RequireAuth>
          }
        />
        <Route
          path="/news/add"
          element={
            <RequireAuth>
              <AddOrEditNews />
            </RequireAuth>
          }
        />
        <Route
          path="/news/edit/:id"
          element={
            <RequireAuth>
              <AddOrEditNews />
            </RequireAuth>
          }
        />
        <Route
          path="/banner"
          element={
            <RequireAuth>
              <Banner />
            </RequireAuth>
          }
        />
      </Routes>
    </ThemeProvider>
  );
};

export default R;
