import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  spacing: {
    xs: 0.5,
    s: 0.75,
    m: 1,
    l: 1.5,
  },
  palettes: {
    red: "#db2828",
    orange: "#f2711c",
    yellow: "#fbbd08",
    olive: "#b5cc18",
    green: "#21ba45",
    teal: "#00b5ad",
    blue: "#2185d0",
    violet: "#6435c9",
    purple: "#a333c8",
    pink: "#e03997",
    brown: "#a5673f",
    grey: "#767676",
    black: "#1b1c1d",
    white: "#fff",
  },
  colors: {
    main: "#161A24",
    main2: "#32DBA1",

    button: "#e0e1e2",
    error: "#db2828",
    border: "#d9d9d9",
  },
  text: {
    s: 0.875,
    m: 1,
  },
  title: {
    s: 1.5,
    m: 2,
  },
  borderRadii: {
    m: 0.25,
  },
};
