import styled, { css } from "styled-components";

const ModalContent = styled.div`
  ${(props) => css`
    min-width: 400px;
    padding: ${props.theme.spacing.m}em ${props.theme.spacing.m}em;
  `}
`;

export default ModalContent;
