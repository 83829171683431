import { ReactNode } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css, useTheme } from "styled-components";

import Box from "../Box";

interface Props {
  isVisible: boolean;
  children: ReactNode;
  hide: () => void;
  title: string;
}

const Container = styled.div<{ isVisible: boolean }>`
  ${(props) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.8);
    display: ${props.isVisible ? "flex" : "none"};
    align-items: center;
    justify-content: center;
  `}
`;

const Content = styled.div`
  ${(props) => css`
    position: relative;
    background: ${props.theme.palettes.white};
    border-radius: ${props.theme.borderRadii.m}em;
  `}
`;

const Header = styled.div`
  ${(props) => css`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${props.theme.colors.border};
    padding: ${props.theme.spacing.m}em ${props.theme.spacing.m}em;
  `}
`;

const CloseBtn = styled.div`
  ${(props) => css`
    cursor: pointer;
    padding: ${props.theme.spacing.xs}em;
  `}
`;

const ChoiceModal = (props: Props) => {
  const theme = useTheme();

  return (
    <Container isVisible={props.isVisible}>
      <Content>
        <Header>
          <Box
            fontSize={`${theme.title.s}em`}
            fontWeight={500}
            color="main"
            flex={1}
          >
            {props.title}
          </Box>
          <CloseBtn onClick={props.hide}>
            <FontAwesomeIcon
              icon={faTimes}
              size="1x"
              color={theme.colors.main}
            />
          </CloseBtn>
        </Header>
        {props.children}
      </Content>
    </Container>
  );
};

export default ChoiceModal;
