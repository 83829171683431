import styled, { css } from "styled-components";

const Footer = styled.div`
  ${(props) => css`
    display: flex;
    justify-content: flex-end;
    padding: ${props.theme.spacing.m}em ${props.theme.spacing.m}em;
  `}
`;

export default Footer;
