import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

import { Box, Button, Input } from "common/components";
import { setToken } from "utils/auth";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const Schema = yup.object().shape({
  username: yup.string().required("Энэ талбар хоосон байна!"),
  password: yup.string().required("Энэ талбар хоосон байна!"),
});

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { handleChange, handleBlur, handleSubmit, values, touched, errors } =
    useFormik({
      validationSchema: Schema,
      initialValues: {
        username: undefined,
        password: undefined,
      },
      onSubmit: () => {
        setIsLoading(true);
        axios
          .post("/user/login", {
            username: values.username,
            password: values.password,
          })
          .then(({ data }) => {
            setToken(data.token);
            setIsLoading(false);
            navigate("/branch");
          })
          .catch((e) => {
            setErrorMsg(e.message);
            setIsLoading(false);
          });
      },
    });

  return (
    <Container>
      <Box width="300px">
        <Input
          placeholder="Нэвтрэх нэр"
          label="Нэтврэх нэр"
          required
          defaultValue={values.username}
          error={touched.username && errors.username !== undefined}
          onChange={handleChange("username")}
          onBlur={handleBlur("username")}
        />
        <Box mt={`${theme.spacing.m}em`}>
          <Input
            placeholder="Нууц үг"
            label="Нууц үг"
            required
            type="password"
            defaultValue={values.password}
            error={touched.password && errors.password !== undefined}
            onChange={handleChange("password")}
            onBlur={handleBlur("password")}
          />
        </Box>
        <Box mt={`${theme.spacing.m}em`}>
          <Button
            title="Нэвтрэх"
            onClick={handleSubmit}
            fluid
            isLoading={isLoading}
          />
        </Box>
        {errorMsg && (
          <Box
            fontFamily="Roboto"
            textAlign="center"
            color={theme.colors.error}
            mt={`${theme.spacing.m}em`}
          >
            {errorMsg}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Login;
