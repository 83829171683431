import { useState } from "react";
import axios from "axios";

import { IImage } from "types";

type Props = {
  cb: (data: IImage) => void;
};

const GalleryHook = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const upload = async (img: any) => {
    setIsLoading(true);

    await axios
      .post("/image/create", img)
      .then(({ data }) => {
        props.cb({ id: data.id, filename: data.filename, path: data.path });
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  };

  return { upload, isLoading };
};

export default GalleryHook;
