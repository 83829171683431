import styled, { css } from "styled-components";

import { lighten } from "utils/color";

const Tbody = styled.tbody`
  ${(props) => css`
    > tr:hover {
      background: ${lighten(props.theme.palettes.yellow, 0.45)};
    }
  `}
`;

export default Tbody;
